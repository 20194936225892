.form {
  text-align: center;
  background-color: $white;
  padding: 60px;
  -webkit-bbox-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
  -ms-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
  margin-top: 100px;
  margin-bottom: 100px;

  @media #{$underxl} {
    margin: 60px 20px;
  }
  @media #{$desktop} {
    margin: 60px 50px;
  }
  @media #{$tablet} {
    margin: 60px 0px;
    padding: 40px;
  }
  @media #{$undermd} {
    margin: 60px 0px;
    padding: 40px;
  }
  @media #{$small-phone} {
    margin: 60px 0px;
    padding: 20px;
  }




  form input[type="text"] {
    border-radius: 4px;
    color: #555555;
    background-color: #ffffff;
    padding: 20px !important;
    border: 0 !important;
    width: 100% !important;
    background: $grey !important;
  }
  form input[type="checkbox"] {
    margin-left: -20px;
    margin-top: 5px !important;

  }
  form textarea {
    width: 100%;
    border-radius: 4px;
    color: #555555;
    padding: 20px !important;
    border: 0 !important;
    width: 100% !important;
    background: $grey !important;
  }
  form .fox-row {
      width: auto;
      max-width: 100%;
      margin: 0 auto;
  }

  .controls{
    margin: 0 auto;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    width: 100% !important;
    text-align: center !important;
  }



  img.fox-item-captcha-img {
      padding: 4px;
      border: 0 !important;
      border-radius: 5px;
      background-color: #ffffff;
      margin-bottom: 5px;
  }

  .control-group label.checkbox {
      font-size: 12px !important;
  }

  .btn{
    background: $red !important;
    padding: 10px 100px !important;
    color: $white !important;
    font-weight: 600  !important;
    border-radius: 0  !important;
    border: none   !important;
    margin-right: 0 !important;
    span {
      color: $white !important;
      font-weight: 600  !important;
      font-size: 20px;
      text-transform: uppercase;
    }

    @media #{$undermd} {
      padding: 10px 80px !important;

    }
  }

  .btn:hover{
    background: $green !important;
  }

  .qq-upload-button {
    background: $darkGrey !important;
    padding: 10px 50px !important;
  }

  .submit-button {
      margin-right: 0px;
  }

  .fox-item-captcha-cnt {
      margin: 0 auto;
      width: 100% !important;
  }


}
.form:after{
  background: $grey;
  height: 250px;
  width: 250%;
  position: absolute;
  content: " ";
  left: -50%;
  z-index: -1;
  top: 400px;
  left: 50%;

  @include transform(translateX(-50%));

  @media #{$underdesktop} {
    display: none;
  }
}
