.lavorazioni, .home{
  .elenchi{
    z-index:1;
    position: relative;
    .item-image{
      background: $green;
      @media #{$landscape-phone} {
        margin: 0 120px;
      }
      @media #{$small-phone} {
        margin: 0 20px;
      }
    }

    .item-image .odd{
      margin-top: 60px;
      @media #{$undermd} {
        margin-top: 20px !important;
      }
      @media #{$small-phone} {
        margin-top: 20px !important;
      }
    }

   .col-sm-3{
      padding: 0;
      margin: 0;
    }
    span{
      position: absolute;
      display: inline-block;
      bottom: 0;
      left: 0%;
      border: none;
      background: transparent !important;
      color: $white !important;
      text-transform: uppercase;
      font-size: 30px;
      @media #{$underdesktop} {
        font-size: 20px;
      }
      @media #{$undermd} {
        font-size: 25px;
      }
    }

    span:before{
      width: 0;
      height: 0;
      border-right: 14px solid $white;
      border-left: none;
      border-top: 25px solid transparent;
      border-bottom: 0px solid transparent;
      content: "";
      position: absolute;
      margin-top: 7px;
      background: transparent;
      margin-left: -20px;
      @media #{$underdesktop} {
        border-right: 14px solid $white;
        border-left: none;
        border-top: 20px solid transparent;
      }
    }
    a:hover{
      img{
        opacity: 0.2;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
      }
      span{
        color:$white !important;
        background: transparent !important;
      }
      span:before{
        width: 0;
        height: 0;
        border-right: 14px solid $white;
        border-left: none;
        border-top: 25px solid transparent;
        border-bottom: 0px solid transparent;
        content: "";
        position: absolute;
        margin-top: 7px;
        background: transparent;
        margin-left: -20px;
        @media #{$underdesktop} {
          border-right: 14px solid $white;
          border-left: none;
          border-top: 20px solid transparent;
          border-bottom: 0px solid transparent;
        }
      }
    }

  }
  #elenchi:after{
    content:"";
    height: 250px;
    width: 100%;
    background: $grey;
    position: absolute;
    margin-top: -300px;
    z-index: -1;
    margin-bottom: 40px;
  }
}

.rivestimenti, .realizzazioni{
  .elenchi{
    background: transparent !important;
    @media #{$undertablet} {
      margin: 0 10px;
    }

    .col-sm-4{
      padding: 0;
    }

    .border-black{
      display: none;
    }

    .item-image{
      background: $green;
      display: block;
      position: relative;
    }

    .items{
      img {
         margin: 0 auto;
         display: block;
         width: 100%;
     }
    }

    span{
      background: transparent !important;
      border:none;
      position: absolute;
      width: 100%;
      right: 0;
      margin-top: -40px;
      @media #{$desktop} {
        margin-top: -60px;
      }
      @media #{$tablet} {
        margin-top: -60px;
      }
      @media #{$small-phone} {
        margin-top: -60px;
      }
    }

    span:before{
      width: 0;
      height: 0;
      border-right: 14px solid $black;
      border-left: none;
      border-top: 20px solid transparent;
      border-bottom: 0px solid transparent;
      content: "";
      position: absolute;
      margin-top: 7px;
      background: transparent;
      margin-left: -20px;

    }
    a:hover{
      img{
        opacity: 0.2;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
      }
      span{
        color:$white !important;
        background: transparent !important;
      }
      span:before{
        width: 0;
        height: 0;
        border-right: 14px solid $white;
        border-left: none;
        border-top: 20px solid transparent;
        border-bottom: 0px solid transparent;
        content: "";
        position: absolute;
        margin-top: 7px;
        background: transparent;
        margin-left: -20px;

      }
    }

  }
}

.realizzazioni{
  .elenchi{
    .items-nolink{

    }
    span{
      background: transparent !important;
      border:none;
      position: absolute;
      width: 100%;
      right: 0;
      margin-top: -40px;
      color: $white !important;
    }

    span:before{
      width: 0;
      height: 0;
      border-right: 14px solid $white !important;
      border-left: none;
      border-top: 20px solid transparent;
      border-bottom: 0px solid transparent;
      content: "";
      position: absolute;
      margin-top: 7px;
      background: transparent;
      margin-left: -20px;

    }

    .items:hover{
      span:before{
        width: 0;
        height: 0;
        border-right: 14px solid $white !important;
        border-left: none;
        border-top: 20px solid transparent;
        border-bottom: 0px solid transparent;
        content: "";
        position: absolute;
        margin-top: 7px;
        background: transparent;
        margin-left: -20px;

      }
      span {
        background: transparent !important;
        color: #fff!important;
      }
    }

  }

}
