#right {
  border-color: $green;
  border-width: 5px;
  position: relative;
  top: 0px;
  bottom:inherit !important;
  background: $green;
  z-index: 1;
  @media #{$undermd} {
    display: none;
  }

  form{
      padding: 10px !important;
      display: none;
  }

  form input[type="text"] {
    border-radius: 4px;
    color: #555555;
    background-color: #ffffff;
    padding: 10px !important;
    border: 0 !important;
    width: 100% !important;
    background: $grey !important;
    font-size: 14px;
  }
  form input[type="checkbox"] {
    margin-left: -20px;
    margin-top: 5px !important;


  }

  label{
      color: $white;
  }
  form textarea {
    width: 100%;
    border-radius: 4px;
    color: #555555;
    padding: 10px !important;
    border: 0 !important;
    width: 100% !important;
    background: $grey !important;
    font-size: 14px;
  }
  form .fox-row {
      width: auto;
      max-width: 100%;
      margin: 0 auto;
  }

  .controls{
    margin: 0 auto;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    width: 100% !important;
    text-align: center !important;
  }



  img.fox-item-captcha-img {
      padding: 4px;
      border: 0 !important;
      border-radius: 5px;
      background-color: #ffffff;
      margin-bottom: 5px;
  }

  .control-group label.checkbox {
      font-size: 12px !important;
  }

  .btn{
    background: $green !important;
    padding: 10px 10px !important;
    color: $white !important;
    font-weight: 300  !important;
    border:1px solid $white !important;
    border-radius: 0  !important;
    margin-right: 0 !important;
    span {
      color: $white !important;
      font-weight: 600  !important;
      font-size: 20px;
      text-transform: uppercase;
    }

    @media #{$undermd} {
      padding: 10px 80px !important;

    }
  }

  .btn:hover{
    background: $green !important;
  }

  .qq-upload-button {
    background: $darkGrey !important;
  }

  .submit-button {
      margin-right: 0px;
  }

  .fox-item-captcha-cnt {
      margin: 0 auto;
      width: 100% !important;
  }



  button{
    color: $white;

  }

  button:hover{
    color: $white;
  }

  .form-control {
      //height: 34px;
      padding: 6px 10px;
      font-weight: 300;
      text-align: left;
      background-color: $white;
      background-image: none;
      border: none;
      color: #000;
      -moz-border-radius: 0;
      border-radius: 0;
      -webkit-box-shadow: none!important;
      -moz-box-shadow: none!important;
      box-shadow: none!important;
  }

  #submit{
    display: inline-block;
    width: 100%;
    border: 1px solid white;
    background: $green;
    border-radius: 0;
    color: $white;
  }

  .handle {
      background-color: gold;
      padding: 20px 20px 20px;
      text-align: center;
      display: block;
      position: relative !important;
      bottom: 0 !important;
      width: 100%;
      left: 0px;
      color: $black;
      margin-bottom: -5px;
      border-radius: 0;
      @media #{$small-phone} {
        display: none;
      }
      @media #{$underdesktop} {
        padding: 5px;
      }

      img{
        left:50%;
        position: absolute;
        @include transform(translateX(-50%));
        top: 10px;
      }
    }
}



pre {
    margin-left: 3em;
}
