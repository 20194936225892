//cta nelle pagine

.container-fluid{
  overflow: hidden;
}
.cta{
  background: $red;
  color: $white;
  font-size: 20px;
  font-weight: 700;
  padding: 10px 30px;
  display: inline-block;
  margin: 20px 0;
  text-decoration: none;
}
.cta:hover{
  background: $green;
  color: $white;
  font-size: 20px;
  font-weight: 700;
  padding: 10px 30px;
  display: inline-block;
  margin: 20px 0;
  text-decoration: none;
}

.breadcrumb{
  text-align: center;
  padding-bottom: 10px !important;
}

.sidebar-left{
  position: relative;
  z-index: 2 !important;
}

.center-cropped
{
  width: 100%;
  height: 600px;
  overflow: hidden;
  position: relative;
  @media #{$underxl} {
    height: 450px;
  }
  @media #{$underdesktop} {
    height: 450px;
  }
  @media #{$undermd} {
    height: 450px;
  }
  @media #{$small-phone} {
    height: inherit;

  }

  img {
    position:absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    min-width: 100%;
    @include transform(translateX(-50%));
    @media #{$tablet} {
      height: auto;
    }
    @media #{$small-phone} {
      height: inherit;
      position: relative;
    }
  }
}

.contenuto{
  //paragrafi

  ul{
    padding: 40px 0 0 0;
    li.even {
      img{
        margin: 0 auto;
      }
      .desc{
        text-align: left;
        padding: 0px 40px 0 40px;
        @media #{$underxl} {
          margin: 0 0 150px;
        }
        @media #{$underdesktop} {
          margin: 0 0 50px;
        }
        @media #{$undermd} {
          text-align: center;
          margin: 40px 0;
        }

        h3{
          font-size: 28px;
          line-height: 45px;
          @media #{$undermd} {
            font-size: 25px;
            margin-bottom: 10px;
          }
        }
        h3:before{
          width: 0;
          height: 0;
          border-left: 15px solid #fff;
          border-top: 0 solid transparent;
          border-bottom: 20px solid transparent;
          content: "";
          position: absolute;
          margin-top: 8px;
          background: $darkGrey;
          margin-left: -30px;
        }
      }

      .desc:after{
        height: 100%;
        width: 250%;
        left: -50%;
        z-index: 1000;
        top: 50%;
        margin-top: 45px;
        background: transparent;
        border: 4px solid $grey;
        content: "";
        position: absolute;
        @include transform(translateY(-50%));
        z-index: -1;
        overflow: hidden;
        @media #{$underdesktop} {
          display: none;
        }
        @media #{$small-phone} {
          display: none;
        }
      }

      .desc:before{
        height: 0px;

      }
    }
    li.odd{
      text-align: right;
      list-style: none;
      margin: 60px 0;
      @media #{$small-phone} {
        margin: 20px 0;
      }
      img{
        margin: 0 auto;
      }
      .desc{
        padding: 0px 40px 0 40px;
        margin-right: 40px;
        @media #{$underxl} {
          margin: 0 0 150px;
        }
        @media #{$underdesktop} {
          margin: 0 0 50px;
        }

        @media #{$undermd} {
          text-align: center;
          margin: 40px 0;
        }
      }

      h3{
        font-size: 30px;
        line-height: 45px;
        @media #{$undermd} {
          font-size: 25px;
          margin-bottom: 10px;
        }

        @media #{$undermd} {
          font-size: 25px;
          margin-bottom: 10px;
        }
      }

      h3:before{
        width: 0;
        height: 0;
        border-right: 15px solid #fff;
        border-top: 0 solid transparent;
        border-bottom: 20px solid transparent;
        right: 0;
        content: "";
        position: absolute;
        background: $darkGrey;
        margin-top: 10px;
        background: $darkGrey;
        margin-left: 20px;
        @media #{$undermd} {
          width: 0;
          height: 0;
          right: auto;
          border-left: 15px solid #fff;
          border-top: 0 solid transparent;
          border-bottom: 20px solid transparent;
          content: "";
          border-right: 0;
          position: absolute;
          margin-top: 8px;
          background: $darkGrey;
          margin-left: -30px;
        }
      }

      .desc:before{
        height: 100%;
        width: 250%;
        left: -100%;
        top: 50%;
        margin-top: 45px;
        border: 4px solid $grey;
        background: transparent;
        content: "";
        @include transform(translateY(-50%));
        position: absolute;
        z-index: -1;
        @media #{$underdesktop} {
          display: none;
        }
        @media #{$small-phone} {
          display: none;
        }
      }
    }
  }

  .vcenter.odd{
    .img{
      @media #{$undermd} {
        margin-top: 10px;
      }
    }
  }
}

.paragrafo{
  margin-top: 100px !important;
  padding-bottom: 60px !important;
  @media #{$underdesktop} {
    padding-bottom: 100px !important;
  }
  .desc{
    margin-top: -100px;

  }
  .desc:after{
    height: 180% !important;
    width: 250%;
    left: -40% !important;
    z-index: 1000;
    top: 20% !important;
    margin-top: 45px;
    background: transparent;
    border: 4px solid #f1f1f1;
    content: "";
    position: absolute;
    @include transform(translateY(-50%));
    z-index: -1;
    @media #{$underxl} {
      top: 40% !important;
      height: 130% !important;
    }
    @media #{$underdesktop} {
      top: 40% !important;
      height: 120% !important;
    }
  }
}


.mappa {
	width: 100%;
  z-index: 0;

}
.mappa iframe{
	width: 100%;

}
.mappa iframe.clicked{
}

.mapscroll-wrap {
  position: static !important;
}
