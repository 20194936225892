.cta-box-sx{
  img{
    margin: 0 auto;
  }
  h3{
    font-size: 35px;
    margin-bottom: 40px;
    text-align: center;
    margin-bottom: 50px;
  }

  h3:before{
    width: 0;
    height: 0;
    border-left: 20px solid #fff;
    border-top: 0px solid transparent;
    border-bottom: 30px solid transparent;
    content: "";
    position: absolute;
    top:0;
    left: 50%;
    margin-top: -40px;
    background: $darkGrey;

  }
  .desc{
    text-align: left;
    @media #{$undermd} {
      text-align: center;
      margin-top: 40px;
    }
  }

  .desc:after {
     height: 200px;
     width: 180%;
     right: -200%;
     top: 50%;
     background: #f1f1f1;
     content: "";
     position: absolute;
     @include transform(translateY(-50%));
     z-index: -1;
     @media #{$underdesktop} {
       display: none;
     }
  }
}

.cta-box-dx{
  img{
    margin: 0 auto;
  }

  h3{
    font-size: 35px;
    margin-bottom: 40px;
    text-align: center;
    margin-bottom: 50px;
  }

  h3:before{
    width: 0;
    height: 0;
    border-left: 20px solid #fff;
    border-top: 0px solid transparent;
    border-bottom: 30px solid transparent;
    content: "";
    position: absolute;
    top:0;
    left: 50%;
    margin-top: -40px;
    background: $darkGrey;
  }
  .desc{
    text-align: right;
    @media #{$undermd} {
      text-align: center;
      margin-top: 40px;
    }
  }

  .desc:before {
     height: 200px;
     width: 180%;
     left: -200%;
     top: 50%;
     background: #f1f1f1;
     content: "";
     position: absolute;
     @include transform(translateY(-50%));
     z-index: -1;
     @media #{$underdesktop} {
       display: none;
     }
  }
}

.cta-center{
  text-align: center;
  margin-top: 50px;
  h3{
    font-size: 35px;
    margin-bottom: 40px;
    text-align: center;
    margin-bottom: 50px;
  }

  h3:before{
    width: 0;
    height: 0;
    border-left: 20px solid #fff;
    border-top: 0px solid transparent;
    border-bottom: 30px solid transparent;
    content: "";
    position: absolute;
    top:0;
    left: 50%;
    margin-top: 0px;
    background: $darkGrey;
    @media #{$tablet} {
      margin-top: -40px;
    }
    @media #{$undertablet} {
      margin-top: -40px;
    }
  }
}
