.contatti{

  overflow-x: hidden;

  h3:before {
      width: 0;
      height: 0;
      border-left: 0px solid #fff !important;
      border-top: 0 solid transparent;
      border-bottom: 0px solid transparent;
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      margin-top: 0;
      background: transparent !important;
  }

  .page-header{
    h1{
        color: $black;
        font-size: 45px;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 5px;
        margin-bottom: 40px;
    }

    p{
      font-size: 20px;
      font-weight:200;
      line-height: 20px;
    }
  }

  a{
    color: $black;
  }
  a:hover{
    color: $green;
  }

  #form{
      text-align: center;
      color: $white;
      z-index:1;
      background-color: $white;
      // transform:skew(0deg,-10deg);
      // -ms-transform:skew(0deg,-10deg);
      // -webkit-transform:skew(0deg,-10deg);
      //padding-bottom: 600px;
      padding-top: 50px;
      color: $black;
      overflow: hidden;

      .bgBox {
        padding-top: 100px;
        padding-bottom: 100px;
        // transform:skew(0deg,10deg);
        // -ms-transform:skew(0deg,10deg);
        // -webkit-transform:skew(0deg,10deg);
        background-image: url('../images/form-bg.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        .container {

          .formBody {
            background-color: $white;
            padding: 80px;
            @include box-shadow(0px 0px 20px 0px rgba(0,0,0,0.2));

            @media #{$small-phone} {
              padding: 30px;
            }

            h2, h3 {
              text-transform: uppercase;
              font-size: 30px;
              font-weight: 200;
              text-align: center;
            }

            .fox-form {

              select, textarea, input[type="text"] {
                border: 1px solid #c0c0c0;
                @include border-radius(0px);
                height: 40px;
                line-height: 40px;
                color: #333333;
                font-weight: 200;
                font-size: 16px;
              }

              .controls {
                margin: 0 auto;
                text-align: center;
                float: none;

                .fox-item-captcha-cnt {
                  margin: 0 auto;
                }

                .radio input[type="radio"], .checkbox input[type="checkbox"] {
                  margin-top: 8px;
                }
                small {
                  font-weight: 200;
                  font-size: 70%;

                  a {
                    font-weight: 200;
                    font-size: inherit;
                    text-decoration: none;
                  }
                }

                .submit-button {
                  margin-bottom: 0px;
                  padding: 15px;
                  border: none;
                  background-color: #eac043;
                  background-image: none;
                  text-shadow: none;
                  min-width: 50%;
                  @include border-radius(0px);

                  span {
                    font-weight: 400;
                    text-transform: uppercase;
                    color: #fff;
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
}
