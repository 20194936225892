.elenchi{
  margin-top: 40px;
  margin-bottom: 60px;
  a, a:visited, a:link{
    span{
      background: $white;
      color: $black;
    }
  }
  a:hover{
    span{
      background: $black;
      color: $white;
    }

    span:before{
      width: 0;
      height: 0;
      border-left: 10px solid $black;
      border-top: 0 solid transparent;
      border-bottom: 15px solid transparent;
      content: "";
      position: absolute;
      margin-top: 6px;
      background: $white;
      margin-left: -15px;
      @media #{$underdesktop} {
        border-left: 10px solid #fff;
        border-top: 0 solid transparent;
        border-bottom: 15px solid transparent;
      }
    }
  }
  .items{
    text-align: center;
    margin-bottom: 40px;
    img{
      margin: 0 auto;
      display: block;
      width: 60%;

    }

    .border-black {
        width: 1px;
        height: 80px;
        margin-top: -50px;
        background: #000;
        position: relative;
        left: 50%;
        z-index: 1;
    }

    span{
      font-size: 20px;
      border:1px solid $black;
      padding: 5px 30px 5px 45px;
      font-weight: 800;
      position: relative;
      display: inline-block;

      @media #{$underdesktop} {
        font-size: 20px;
      }
    }

    span:before{
      width: 0;
      height: 0;
      border-left: 10px solid #fff;
      border-top: 0 solid transparent;
      border-bottom: 15px solid transparent;
      content: "";
      position: absolute;
      margin-top: 6px;
      background: $black;
      margin-left: -15px;
      @media #{$underdesktop} {
        border-left: 10px solid #fff;
        border-top: 0 solid transparent;
        border-bottom: 15px solid transparent;
      }
    }
  }


  .description{
    background: $green;
    color: $white;
    background-color: #05418d;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    opacity: 0;
    top:0;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    .titolo{
      font-size: 20px;
      font-weight: 700;
      padding: 20px 0 0;
    }

    .descrizione{
      font-size: 16px;
      font-weight: 300;

    }
  }

  .items:hover{
    .description{
      background: $green;
      opacity: 0.9;
      z-index: 10;
    }
    span{
      background: $black !important;
      color: $white !important;
    }

    span:before{
      width: 0;
      height: 0;
      border-left: 10px solid $black;
      border-top: 0 solid transparent;
      border-bottom: 15px solid transparent;
      content: "";
      position: absolute;
      margin-top: 6px;
      background: $white;
      margin-left: -15px;
      @media #{$underdesktop} {
        border-left: 10px solid #fff;
        border-top: 0 solid transparent;
        border-bottom: 15px solid transparent;
      }
    }
  }

  .mod-article-link{

    a:hover{
      span{
        background: $black !important;
        color: $white !important;

      }

      span:before{
        width: 0;
        height: 0;
        border-left: 10px solid $black;
        border-top: 0 solid transparent;
        border-bottom: 15px solid transparent;
        content: "";
        position: absolute;
        margin-top: 6px;
        background: $white;
        margin-left: -15px;
        @media #{$underdesktop} {
          order-left: 10px solid #fff;
          border-top: 0 solid transparent;
          border-bottom: 15px solid transparent;
        }
      }
    }
  }
}
.pietre-naturali, .pavimenti{
  .items{
    span{
      font-size: 30px !important;
      border:1px solid $black;
      padding: 5px 30px 5px 45px;
      font-weight: 800;
      position: relative;
      display: inline-block;
      text-transform: uppercase !important;

      @media #{$underxl} {
        font-size: 25px !important;
      }
      @media #{$underdesktop} {
        font-size: 20px !important;
      }
    }

    span:before{
      width: 0;
      height: 0;
      border-left: 20px solid #fff !important;
      border-top: 0 solid transparent;
      border-bottom: 25px solid transparent !important;
      content: "";
      position: absolute;
      margin-top: 10px !important;

      background: $black;
      margin-left: -25px !important;
      @media #{$underxl} {
        border-left: 15px solid #fff !important;
        border-top: 0 solid transparent;
        border-bottom: 20px solid transparent !important;
        margin-top: 8px !important;
      }
      @media #{$underdesktop} {
        border-left: 14px solid #fff!important;
        border-top: 0 solid transparent;
        border-bottom: 16px solid transparent!important;
        margin-top: 8px !important;
      }
    }
  }


  .items:hover{
    .description{
      background: $green;
      opacity: 0.9;
      z-index: 10;
    }
    span{
      background: $black !important;
      color: $white !important;
    }

    span:before{
      width: 0;
      height: 0;
      border-left: 20px solid $black !important;
      border-top: 0 solid transparent;
      border-bottom: 30px solid transparent;
      content: "";
      position: absolute;
      margin-top: 6px;
      background: $white;
      margin-left: -15px;
      @media #{$underxl} {
        border-left: 15px solid $black !important;
        border-top: 0 solid transparent;
        border-bottom: 20px solid transparent;
        margin-top: 8px !important;
      }
      @media #{$underdesktop} {
        order-left: 10px solid #fff;
        border-top: 0 solid transparent;
        border-bottom: 15px solid transparent;
      }
    }
  }

}

.items{
  .description{

    .titolo{
      padding: 0;
    }
    .descrizione{
      position: relative;
      top:50%;
      @include transform(translateY(-50%));
    }
    p{
      margin: 0 !important;
    }

  }
}
