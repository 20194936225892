.pages{
  padding: 0;
  li:nth-child(2n+1) {
    .desc-pages{
      text-align: left;
      padding: 0px 40px 0 40px;

      @media #{$underxl} {
        margin: 0px 0;
      }
      @media #{$undermd} {
        text-align: center;
        margin: 40px 0;
      }

      h3{
        font-size: 30px;
        line-height: 35px;
        padding-bottom: 25px;
        @media #{$undermd} {
          font-size: 25px;
          margin-bottom: 10px;
        }
      }
      h3:before{
        width: 0;
        height: 0;
        border-left: 15px solid #fff;
        border-top: 0 solid transparent;
        border-bottom: 20px solid transparent;
        content: "";
        position: absolute;
        margin-top: 8px;
        background: $darkGrey;
        margin-left: -30px;
      }
    }

    .desc-pages:after{
      height: 200px;
      width: 100%;
      right: -100%;
      top:50%;
      background: $grey;
      content: "";
      border: none;
      position: absolute;
      @include transform(translateY(-50%));
      z-index: -1;
      @media #{$underdesktop} {
        display: none;
      }
    }

    .desc-pages:before{
      height: 0px;
    }
  }
  li{
    text-align: right;
    list-style: none;
    margin: 20px 0;
    @media #{$small-phone} {
      margin: 20px 0;
    }
    img{
      margin: 0 auto;
    }
    .desc-pages{
      padding: 0px 40px 0 40px;
      margin-right: 40px;
      z-index: 1;
      position: relative;
      @media #{$underxl} {
        margin: 0px 0;
      }

      @media #{$undermd} {
        text-align: center;
        margin: 40px 0;
      }
    }

    h3{
      line-height: 35px;
      padding-bottom: 25px;

      @media #{$undermd} {
        font-size: 25px;
        margin-bottom: 10px;
      }
    }

    h2:before{
      width: 0;
      height: 0;
      border-right: 15px solid #fff;
      border-top: 0 solid transparent;
      border-bottom: 20px solid transparent;
      right: 0;
      content: "";
      position: absolute;
      background: $darkGrey;
      margin-top: 10px;
      background: $darkGrey;
      margin-left: 20px;
      @media #{$undermd} {
        width: 0;
        height: 0;
        right: auto;
        border-left: 15px solid #fff;
        border-top: 0 solid transparent;
        border-bottom: 20px solid transparent;
        content: "";
        border-right: 0;
        position: absolute;
        margin-top: 8px;
        background: $darkGrey;
        margin-left: -30px;
      }
    }

    .desc-pages:before{
      height: 200px;
      width: 100%;
      left: -100%;
      top:50%;
      background: $grey;
      border: none;
      content: "";
      @include transform(translateY(-50%));
      position: absolute;
      z-index: -1;
      @media #{$underdesktop} {
        display: none;
      }
    }
  }
}
