.contatti{
    p{
      margin-top:0;
    }

    p.contatto{

      font-weight: 700;
      position: relative;
      bottom: 0;
      left: 50%;
      @include transform(translateX(-50%));
      border: none;
      background: transparent !important;
      color: $green !important;
      text-transform: uppercase;
      border: 1px solid $green;
      font-size: 25px;
      display: table;
      padding: 10px 20px 10px 40px;
      @media #{$underdesktop} {
        font-size: 20px;
      }
    }

    p.contatto:before{
      width: 0;
      height: 0;
      border-right: 14px solid $green;
      border-left: none;
      border-top: 20px solid transparent;
      border-bottom: 0px solid transparent;
      content: "";
      position: absolute;
      margin-top: 3px;
      background: transparent;
      margin-left: -20px;

    }

    .border-black {
        width: 1px;
        height: 80px;
        margin-top: -50px;
        background: $green;
        position: relative;
        left: 50%;
        z-index: -1;
    }

    .blocco-cont
    {
      display: block;
      margin-top: 100px;
      img{
        background: $green;
        margin: 0 auto;
        padding: 20px;
        border-radius: 50%;
      }
    }


 }
