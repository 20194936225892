#header {
  .img-taglio{
    margin-top: -120px;
    position: relative;
    background-image: url("../images/header_taglio.png");
    background-repeat: repeat-x;
    background-position: bottom center;
    height: 120px;
  }

  img {
    margin: 0 auto;
  }

  figure {

    position: relative;
    overflow: hidden;

    @media #{$undertablet} {
      height: 600px;
    }
    @media #{$small-phone} {
      height: 400px;
    }
  }

  figure img {
    position: relative;
    top: 0;
    left: 50%;
    margin: 0 auto;
    min-width: 100%;
    @include transform(translateX(-50%));

  }


  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));

    @media #{$undertablet}{
      left: 0;
      @include transform(translateY(-50%));
    }
  }

  figcaption {
    width: 100%;
    top:50%;
    position: absolute;
    left: 50%;
    @include transform(translate(-50%,-50%));
    @media #{$small-phone} {
      width: 90%;
    }
    .cont-title{
      background: $white;
      text-align: center;
      padding: 30px 0;
      background-color: rgba(255,255,255,.7);
    }

    .title{
      font-size: 80px;
      font-weight: 700;
      line-height: 50px;
      @media #{$underdesktop}{
        font-size: 70px;
        font-weight: 500;
        line-height: 40px;
      }
      @media #{$undermd}{
        font-size: 50px;
        font-weight: 500;
        line-height: 50px;
      }
      @media #{$small-phone}{
        font-size: 40px;
        line-height: 40px;
      }
    }

    .intro-title{
      font-size: 40px;
      line-height: 45px;
      @media #{$undertablet}{
        font-size: 25px;
        line-height: 25px;
      }
      @media #{$small-phone}{
        font-size: 25px;
        line-height: 25px;
      }
    }
  }

}
