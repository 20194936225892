.gallery{
    margin-top: 80px;

  .slide{
    text-align: center;
    margin-bottom: 40px;
    position: relative;
    img{
      margin: 0 auto;
      display: block;
      @media #{$undermd} {
        margin-top: 40px;
      }
    }

    .border-black {
        width: 1px;
        height: 80px;
        margin-top: -50px;
        background: #000;
        position: relative;
        left: 50%;
        z-index: 1;
    }

    span{
      font-size: 20px;
      border:1px solid $black;
      padding: 5px 10px 5px 25px;
      font-weight: 800;
      position: relative;
      display: inline-block;

      @media #{$underdesktop} {
        font-size: 20px;
      }
    }

    span:before{
      width: 0;
      height: 0;
      border-left: 10px solid #fff;
      border-top: 0 solid transparent;
      border-bottom: 15px solid transparent;
      content: "";
      position: absolute;
      margin-top: 6px;
      background: $black;
      margin-left: -15px;
      @media #{$underdesktop} {
        order-left: 10px solid #fff;
        border-top: 0 solid transparent;
        border-bottom: 15px solid transparent;
      }
    }
  }


  .description{
    background: $green;
    color: $white;
    padding: 0 20px;
    background-color: #05418d;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    opacity: 0;
    top:0;
    left: 50%;
    z-index: 10;
    @include transform(translateX(-50%));

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    p{
      font-size: 14px;
          line-height: 14px;
    }

    .titolo{
      font-size: 20px;
      font-weight: 700;
      padding: 20px 0 0;
    }

    .descrizione{
      font-size: 16px;
      font-weight: 300;

    }
  }

  .slide:hover{
    .description{
      background: $green;
      opacity: 0.9;
      z-index: 10;
    }
    span{
      background: $black !important;
      color: $white !important;
    }

    span:before{
      width: 0;
      height: 0;
      border-left: 10px solid $black;
      border-top: 0 solid transparent;
      border-bottom: 15px solid transparent;
      content: "";
      position: absolute;
      margin-top: 6px;
      background: $white;
      margin-left: -15px;
      @media #{$underdesktop} {
        border-left: 10px solid #fff;
        border-top: 0 solid transparent;
        border-bottom: 15px solid transparent;
      }
    }
  }
  .slick-slider{
    position: relative;
  }

  .slick-prev, .slick-prev:hover, .slick-prev:focus {
      color: transparent;
      outline: none;
      background-image: url(../images/arrow_left.png);
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
      background-size: 40px;
      left: -85px;
      @media #{$underxl} {
        left: -45px;
      }
      @media #{$underdesktop} {
        left: -45px;
      }
      @media #{$undermd} {
        left:0px;
      }
      @media #{$small-phone} {
        left: -15px;
      }
  }
  .slick-next, .slick-next:hover, .slick-next:focus {
      background-image: url(../images/arrow_right.png);
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
      background-size: 40px;
      right: -85px;
      @media #{$underxl} {
        right: -45px;
      }
      @media #{$underdesktop} {
        right: -45px;
      }
      @media #{$undermd} {
        right: 0px;
      }
      @media #{$small-phone} {
        right: -15px;
      }
  }

  .slick-next:before, .slick-prev:before {
      font-family: slick;
      font-size: 0px;
      line-height: 1;
      opacity: .75;
      color: #fff;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  .slick-next, .slick-prev {
    width: 50px;
    height: 200px;
  }

}

.gallery-marmi{
  margin-top: 50px;
  .center-cropped{
    height: 200px;
    img {
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        margin: auto;
        height: auto;
        min-width: 100%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }
  }
}

.gallery-foto{


  .img-gal{
    width: 33.33%;
    display: block;
    float: left;
  }

  .img-gal:hover{
    img{
      opacity: 0.2;
    }
    background: $green;

    i{
      display: block !important;
      color: $white;
      left: 50%;
      position: absolute;
      z-index: 99;
      top: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      border-radius: 50%;
      border:1px solid $white;
      padding: 5px 7px;
    }
  }
}

.gallery-marmi{
  .slick-prev, .slick-prev:hover, .slick-prev:focus {
      color: transparent;
      outline: none;
      background-image: url(../images/arrow_left.png);
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
      background-size: 40px;
      left: -85px;
      @media #{$underxl} {
        left: -45px;
      }
      @media #{$underdesktop} {
        left: -45px;
      }
      @media #{$undermd} {
        left:0px;
      }
      @media #{$small-phone} {
        left: -15px;
      }
  }
  .slick-next, .slick-next:hover, .slick-next:focus {
      background-image: url(../images/arrow_right.png);
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
      background-size: 40px;
      right: -85px;
      @media #{$underxl} {
        right: -45px;
      }
      @media #{$underdesktop} {
        right: -45px;
      }
      @media #{$undermd} {
        right: 0px;
      }
      @media #{$small-phone} {
        right: -15px;
      }
  }

  .slick-next:before, .slick-prev:before {
      font-family: slick;
      font-size: 0px;
      line-height: 1;
      opacity: .75;
      color: #fff;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  .slick-next, .slick-prev {
    width: 50px;
    height: 200px;
  }

}
