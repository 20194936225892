#accordion {
	list-style:none;
	color: $white;
  padding: 0 0 5px;
	background: $green;
	@media #{$undermd} {
		display: none;
	}
}

#accordion li {
	font-size:14px;
	cursor:pointer;
	padding-top: 15px;
	padding-bottom: 15px;
	color: $white;


	a{
		color:$white !important;
		font-weight: 400;
		font-size: 20px;
		margin-left: 10px;
		text-decoration: none;
		@media #{$underxl} {
      font-size: 16px;
    }
		@media #{$desktop} {
		 font-size: 14px;
		}
		img{
			width: 25px;
			margin-right: 10px;
			@media #{$underxl} {
	      width: 20px;
				margin-right: 5px;
			}
			@media #{$desktop} {
	     display: none;
			}
		}

	}

	a:after{
		font-family: FontAwesome;
		font-style: normal;
		font-weight: 400;
		text-decoration: inherit;
		content: "\f054";
		position: absolute;
		right: 25px;
		pointer-events: none;
	   cursor: default;
		 @media #{$underdesktop} {
			right: 10px;
		 }
	}
}

#accordion li.active{
	a:after{
		-webkit-transform: rotate(90deg);
		-moz-transform: rotate(90deg);
		-o-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
	}
}

#accordion ul {
	list-style:none;
	margin:0;
	padding:5px 0;
	background: $darkGreen;
    width: 100%;
		margin-top: 10px;
		display: none;

	li {
		border:0;
		padding-left:15px;
		padding: 10px 0;
		margin-left: 20px;
		border-left: 1px solid $white;


	@media #{$underxl} {
		border-left:none;
		text-indent:-20px;
 		margin-left:20px;
	}

	  a:after{
			content: "";
			position: absolute;
			right: 25px;
			pointer-events: none;
	   	cursor: default;
		}

		a {
			text-decoration:none;
			color:$white !important;
			font-size: 16px;
			@media #{$underxl} {
	      font-size: 13px;
	    }
		}

		a:hover {
			color:#000;
		}
	}
}

#sticky-wrapper{
	#accordion{
			position: relative;
	}
}
