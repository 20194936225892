/******* Footer *******/
footer {
  background-color: $black;
  color: $white;
  text-align: center;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
  display: block;
  width:100%;
  z-index: -1;
  position: relative;
  margin-top: 50px;

  .border-top{
    height: 1px;
    width: 100%;
    background: $white;
  }

  .social, .logo{
    position: relative;
    top:50%;
    @include transform(translateY(-50%));
    @media #{$small-phone} {
      top:0%;
      @include transform(translateY(0%));

    }
  }

  .contatti{
    text-align: left;
    padding-top: 20px;
    @media #{$undermd} {
      font-size: 16px;
    }
    @media #{$small-phone} {
      text-align: center;
      margin-top: 20px;
      font-size: 14px;
    }
  }

  p{
    margin-top: 20px;
    font-size: 12px;
  }

  a {
      color: $white;
      font-weight: 300;
      font-size: 14px;
      text-decoration: none;
  }

  a:focus,  a:hover {
      color: $grey;
      text-decoration: none;
      font-size: 14px;
      font-weight: 300;
  }

  span{
    margin: 0px;
    float: right;
    padding-left: 60px;
    padding-right: 10px;
    @media #{$undermd} {
      padding-left: 0px;
    }
  }

  .btn-newsletter{
    background: $green;
    border-radius: 0;
    text-decoration: underline;
    color: $white;
    display: inline-block;
  }

  i{
    font-size: 30px !important;
    padding: 0 10px;
  }

  .logo-footer{
    width: 100px;
    margin: 10px 0;
    @media #{$undermd} {
      width: 80px;
      margin: 40px;
    }

  }

  .icona-mail{
    background: white;
    padding: 10px;
    float: left;
    position: absolute;
    top: -34px;
    @include transform(translateY(50%));
    width: 50px;
    @media #{$undermd} {
      display: none;
    }
  }

  .social-button {
    text-align: center;
    margin-top: 20px;

    .fa-youtube-play{
      border-left: 1px solid $white;
      border-right: 1px solid $white;
    }

    a {
      font-size: 20px;
      height: 25px;
      line-height: 20px;
      padding: 2px;
      text-align: center;
      width: 25px;
      color: $white;
    }

    a:hover,
    a:focus {
        color: $grey;
    }
  }
}

// Copyright
.copyright {
  text-align: center;
  color: #ffffff;

  a{
    color: #999999;
    font-size: 10px;
    color: $white;
    font-weight: 200;
    text-decoration: none;
  }

  a:hover, a:focus{
    color: #eac043;
  }

  small {
    font-size: 10px;
  }
}
