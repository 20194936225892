.home{
  background: $grey;

  #sb-site{
    background: $grey !important;
  }
  #header{
    background: $grey;

    h1{
      top: 30%;
      position: absolute;
      left: 30%;
      font-size: 80px;
      line-height: 80px;
      font-weight: 300;
      @media #{$underxl} {
        left: 20%;
        font-size: 60px;
        line-height: 60px;
      }
      @media #{$undermd} {
        position: relative;
        left: 0%;
        margin: 40px 0;
        text-align: center;
      }
      @media #{$undertablet} {
        font-size: 50px;
        line-height: 55px;
      }
      @media #{$small-phone} {
        font-size: 40px;
        line-height: 45px;
      }
    }

    img{
      position: relative;
      z-index: 1;
      @media #{$underxl} {
        width: 95%;
      }
      @media #{$undermd} {
        margin: 0 auto;
        width: 55%;
        left: 50%;
        @include transform(translateX(-50%));
      }

    }
  }

  h2{
    font-size: 40px;
    padding-bottom: 25px;
  }

  h2:before{
    width: 0;
    height: 0;
    border-left: 20px solid #fff;
    border-top: 0 solid transparent;
    border-bottom: 30px solid transparent;
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-top: -40px;
    background: #a0a0a0;
  }

  h3{
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 40px;
    font-style: italic;
    font-weight: 300;
  }



  em{
    font-size: 30px;
    line-height: 35px;
    padding-bottom: 35px;
    position: relative;
    display: block;
  }

  footer{
    margin-top: 0;
  }

  .elenchi{
    z-index:1;
    position: relative;
    .item-image{
      background: $green;
      @media #{$small-phone} {
        margin: 0 120px;
      }
    }

    .item-image .odd{
      margin-top: 60px;
      @media #{$undermd} {
        margin-top: 20px !important;
      }
      @media #{$small-phone} {
        margin-top: 20px !important;
      }
    }

   .col-sm-3{
      padding: 0;
      margin: 0;
    }
    span{
      position: absolute;
      display: inline-block;
      bottom: 0;
      left: 0%;
      border: none;
      background: transparent !important;
      color: $white !important;
      text-transform: uppercase;
      font-size: 30px;
      @media #{$underdesktop} {
        font-size: 25px;
      }
      @media #{$undermd} {
        font-size: 25px;
      }
    }

    span:before{
      width: 0;
      height: 0;
      border-right: 14px solid $white;
      border-left: none;
      border-top: 25px solid transparent;
      border-bottom: 0px solid transparent;
      content: "";
      position: absolute;
      margin-top: 7px;
      background: transparent;
      margin-left: -20px;
      @media #{$underdesktop} {
        border-right: 14px solid $white;
        border-left: none;
        border-top: 20px solid transparent;
      }
    }
    a:hover{
      img{
        opacity: 0.2;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
      }
      span{
        color:$white !important;
        background: transparent !important;
      }
      span:before{
        width: 0;
        height: 0;
        border-right: 14px solid $white;
        border-left: none;
        border-top: 25px solid transparent;
        border-bottom: 0px solid transparent;
        content: "";
        position: absolute;
        margin-top: 7px;
        background: transparent;
        margin-left: -20px;
        @media #{$underdesktop} {
          border-right: 14px solid $white;
          border-left: none;
          border-top: 20px solid transparent;
          border-bottom: 0px solid transparent;
        }
      }
    }

    #elenchi:after{
      content:"";
      height: 250px;
      width: 100%;
      background: $grey;
      position: absolute;
      margin-top: -300px;
      z-index: -1;
      margin-bottom: 40px;
    }
  }
}


.homeBox1{
  text-align: right;
  color: $black;
  z-index: 0;
  background: $white;
  transform: skew(0deg,-10deg);
  -ms-transform: skew(0deg,-10deg);
  -webkit-transform: skew(0deg,-10deg);
  padding-bottom: 300px;
  margin-bottom: -300px;

  .custom-center{
    transform: skew(0deg,10deg);
    -ms-transform: skew(0deg,10deg);
    -webkit-transform: skew(0deg,10deg);
    margin-top: 200px;
    @media #{$undermd} {
      margin-top: 100px;
    }
  }
}

.homeBox4{
  .col-sm-6{
    margin-top: 60px;
    margin-bottom: 60px;
    @media #{$undermd} {
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }
    @media #{$small-phone} {
      margin-top: 20px !important;
      margin-bottom: 20px !important;
    }

    p{
      border: none;
      background: transparent!important;
      color: #fff!important;
      text-transform: uppercase;
      font-size: 30px;
      margin-top: -40px;
      font-weight: 600;
      margin-left: 40px;
      position: relative;
      @media #{$underxl} {
        font-size: 25px;
      }
    }

    p:before{
      width: 0;
      height: 0;
      border-right: 14px solid $white !important;
      border-left: none;
      border-top: 20px solid transparent;
      border-bottom: 0px solid transparent;
      content: "";
      position: absolute;
      margin-top: 2px;
      background: transparent;
      margin-left: -20px;
      @media #{$underdesktop} {
        border-right: 14px solid $white !important;
        border-left: none;
        border-top: 20px solid transparent;
        border-bottom: 0px solid transparent;
      }
    }

    a{
      background: $green;
      display: block;
      position: relative;
      @media #{$undermd} {
        margin-top: 0px !important;
      }
    }

    a:hover{
      img{
        opacity: 0.2;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
      }
      p{
        color:$white !important;
        background: transparent !important;
      }
      p:before{
        width: 0;
        height: 0;
        border-right: 14px solid $white;
        border-left: none;
        border-top: 20px solid transparent;
        border-bottom: 0px solid transparent;
        content: "";
        position: absolute;
        margin-top: 7px;
        background: transparent;
        margin-left: -20px;
        @media #{$underdesktop} {
          border-left: 10px solid #fff;
          border-top: 0 solid transparent;
          border-bottom: 15px solid transparent;
        }
      }
    }
  }


}


.homeBox2, .homeBox3, .homeBox4, .homeBox5{
  background: $white;
}


.homeBox3{
  position: relative;
  z-index: 0;

  @media #{$undermd} {
    padding-top: 0px;
    background: $white;
    top:0;
  }
  @media #{$small-phone} {
    top: 0px !important;
  }
}

.homeBox5{
  padding-bottom: 100px;
}
