.elenchi-tab{
  padding: 80px 0;

  .contenitore_tabs{
    position: relative;
    padding: 10px;
    @media #{$underxl} {
      padding: 10px 10px 80px;
    }
    @media #{$underdesktop} {
      margin-top: 0;
      top:0;
      left:0;
      @include transform(translate(0%,0%));
    }
    @media #{$undermd} {
      margin-top: 0;
      top:0;
      left:0;
      @include transform(translate(0%,0%));
    }
    @media #{$undertablet} {
      margin-top: 0;
      top:0;
      left:0;
      @include transform(translate(0%,0%));
    }
  }

  p{
    @media #{$underxl} {
      font-size: 18px;
    }

  }

  .description-tab {
      background: $white;
      border: none;
      position: relative;
      margin: 10px 10px -10px 0px;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(140,140,140,1);
      -moz-box-shadow: 0px 0px 5px 0px rgba(140,140,140,1);
      box-shadow: 0px 0px 5px 0px rgba(140,140,140,1);

  }

  .items-tab{
    text-align: center;
    margin-bottom: 40px;
    img{
      margin: 0 auto;
      display: block;
      //width: 60%;
      top: 50%;
      left:50%;
      position: relative;
      @include transform(translate(-50%,-50%));

      @media #{$underdesktop} {
        margin-top: 0;
        top:0;
        left:0;
        @include transform(translate(0%,0%));
      }
      @media #{$undermd} {
        margin-top: 0;
        top:0;
        left:0;
        @include transform(translate(0%,0%));
      }
      @media #{$undertablet} {
        margin-top: 0;
        top:0;
        left:0;
        @include transform(translate(0%,0%));
      }
    }

    .border-black {
        width: 1px;
        height: 80px;
        margin-top: -50px;
        background: #000;
        position: relative;
        left: 50%;
        z-index: 1;
    }

    .tab-button{
      position: absolute;
      display: block;
      bottom: 10px;
      left: 50%;
      width: 100%;
      @include transform(translateX(-50%));

      @media #{$desktop} {
        left: 50%;
        @include transform(translateX(-50%));
      }
      @media #{$undermd} {
        left: 50%;
        @include transform(translateX(-50%));
      }
      @media #{$undertablet} {
        bottom: 10px;
      }

    }

    .descrizione{
      position: relative;
      top: 50%;
      @include transform(translateY(-50%));
    }

    span{
      position: absolute;
      font-size: 20px;
      border:1px solid $black;
      padding: 5px 10px 5px 10px;
      font-weight: 800;
      bottom: 0;
      position: relative;
      display: inline-block;
      margin: 0px 0px;
      position: relative;

      @media #{$underxl} {
        padding: 5px 5px;
      }
      @media #{$desktop} {
        padding: 2px;
      }
      @media #{$underdesktop} {
        font-size: 20px;
        margin: 2px;
      }
      @media #{$small-phone} {
        padding: 2px;
      }
    }
    span:hover{
      position: absolute;
      font-size: 20px;
      border:1px solid $black;
      padding: 5px 10px 5px 10px;
      font-weight: 800;
      bottom: 0;
      position: relative;
      display: inline-block;
      position: relative;
      color: $white;
      background: $black;
      @media #{$underxl} {
        padding: 5px 5px;
      }
      @media #{$underdesktop} {
        font-size: 20px;
      }
      @media #{$small-phone} {
        padding: 5px 5px;
      }
    }

    a{
      margin: 0px 2px;
      color: $black;
      @media #{$desktop} {
        margin: 0px;
      }
    }

    a.active{
      span{
        position: absolute;
        font-size: 20px;
        border:1px solid $black;
        padding: 5px 5px;
        font-weight: 800;
        bottom: 0;
        position: relative;
        display:inline-block;
        position: relative;
        color: $white;
        background: $black;

        @media #{$desktop} {
          display: inline-table;
          margin: 0 20px;
          margin-bottom: 10px;
          margin-right: 0px;
          margin-left: 0px;
          padding: 2px;
          margin: 2px;
        }

        @media #{$underxl} {
          margin: 2px;
        }
        @media #{$desktop} {
          padding: 2px;
          margin: 2px;
        }
        @media #{$underdesktop} {
          font-size: 20px;
          display: inline-block;

        }
        @media #{$small-phone} {
          padding: 2px;
        }


      }

    }

    h4{
      text-align: left;
      font-size: 25px;
      margin-left: 20px;
    }

    h4:before{
      width: 0;
      height: 0;
      border-left: 15px solid #fff;
      border-top: 0 solid transparent;
      border-bottom: 20px solid transparent;
      content: "";
      position: absolute;
      margin-top: 15px;
      background: $black;
      margin-left: -20px;
      @media #{$underdesktop} {
        border-left: 10px solid #fff;
        border-top: 0 solid transparent;
        border-bottom: 15px solid transparent;
      }
    }

    .misure, .finiture{
      display: none;
    }
  }

}
