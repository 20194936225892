/******** Colori *********/
/******** Basic *******/
$black: #000;
$white: #FFF;
$green: #46887e; //Sfondo menu e info laterali – hover immagini
$darkGreen: #366a62;  //fascia sotto scritta LAVORAZIONE (del menu laterale)
$grey: #f1f1f1;  //fasce grige
$darkGrey: #a0a0a0;  //triangolino sopra i titoli delle pagine
$red: #d12d3c;  //bottone invia

/******** Colori cornici *******/
$edera: #d8ceaf; // CORNICE EDERA BIS
$sigaro: #ece0ca; // CORNICE SIGARO
$matita: #b9cbba; // CORNICE MATITA
$bombato: #dfe0de;  // CORNICE BOMBATO
$onda: #d2d1cb; //CORNICE ONDA
$eritage: #b2aea5; //CORNICE ERITAGE
$london: #8e8e8e; //CORNICE LONDON
$karma: #dddcd7; //CORNICE KARMA
$moderno: #676869; //CORNICE MODERNO
$toro1: #414849;  //CORNICE TORO 1 GRADINO
$toro2: #dadada; // CORNICE TORO 2 GRADINI

/******** Colori zoccolini *******/
$arrotondata: #7f868e; // ZOCCOLINO COSTA ARROTONDATA
$bisellata: #5a646e; // ZOCCOLINO COSTA BISELLATA
$sagomatoA: #dfc6ab; // ZOCCOLINO SAGOMATO A
$sagomatoB: #5c7a72; // ZOCCOLINO SAGOMATO B
$sagomatoC: #867267; // ZOCCOLINO SAGOMATO C
$sagomatoD: #d4c0af; // ZOCCOLINO SAGOMATO D
$sagomatoE: #f3efea; // ZOCCOLINO SAGOMATO E
$sagomatoF: #e7dbcc; // ZOCCOLINO SAGOMATO F
$sagomatoG: #f3e0c9; // ZOCCOLINO SAGOMATO G
$sagomatoH: #d7dee0; // ZOCCOLINO SAGOMATO H

/*** Risoluzioni per mobile ***/
$small-phone: "(max-width: 575px)";
$landscape-phone: "(min-width: 576px) and (max-width: 767px)";
$tablet: "(min-width: 768px) and (max-width: 991px)";
$undertablet: "(max-width: 767px)";
$desktop: "(min-width: 992px) and (max-width: 1199px)";
$underdesktop: "(max-width: 1199px)";
$underxl: "(max-width: 1599px)";
$desktop-xl: "(min-width: 1299px) and (max-width: 1599px)";
$under-desktop-xl: "(min-width: 992px) and (max-width: 1299px)";
$tablet-landscape: "(min-width: 992px) and (max-width: 1024px)";
$undermd: "(max-width: 991px)";
$extra-desktop: "(min-width: 1200px)";

/*** Mixin ***/
@mixin rotate($rotate) {
  -webkit-transform: rotate($rotate);
  -moz-transform: rotate($rotate);
  -o-transform: rotate($rotate);
  -ms-transform: rotate($rotate);
  transform: rotate($rotate);
}

@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  -o-transform: $transforms;
  transform: $transforms;
}

@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin box-shadow($boxshadow) {
  -webkit-bbox-shadow: $boxshadow;
  -moz-box-shadow: $boxshadow;
  -ms-box-shadow: $boxshadow;
  box-shadow: $boxshadow;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin linearGradient($top, $bottom){
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}


/******** General ********/
body {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 300;
}

p {
  font-size: 18px;
  font-weight: 300;
  line-height: 29px;
}

a{
  color: black;
  font-weight: 400;
  font-size: 18px;
  text-decoration: underline;
  @include transition(all 0.2s ease 0s);
}
section{
  padding: 0px 0;
}

a:hover,  a.active{
  color: $green;
  font-weight: 400;
  font-size: 18px;
  text-decoration: underline;
}

button:hover, h1:hover, h2:hover, h3:hover,
button:focus, h1:focus, h2:focus, h3:focus {
  text-decoration: none;
  outline: 0;
  @include transition(all 0.2s ease 0s);
}

button{
  padding: 15px 30px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: grey;
  color: black;
  font-size: 18px;
  border: none;
  text-decoration: none;
}

button:focus, button:hover {
  @include transition(all 0.3s ease 0s);
  color: $black;
}

b, strong {
    font-weight: 600;
}

em {
}

i{
  line-height: 30px;
}

small{
  font-size: 16px;
}

.h1, .h2, .h3, h1, h2, h3, h4 {
    margin-bottom: 0px;
    margin-top: 0px;
    font-weight: 600;
    line-height: 50px;
    font-family: 'Lato', sans-serif;

    @media #{$small-phone} {
      line-height: 40px;
    }
}

h1{
  font-size: 45px;
  padding-bottom: 25px;

}
h2{
  font-size: 35px;
  padding-bottom: 40px;
  line-height: 40px;
  font-weight: normal;
}

hr {
    margin: 30px 0;
    border: 1px solid #dfdfdf;
}

blockquote{
  padding: 10px 0px;
  margin: 0 0 20px;
  font-size: 40px;
  font-weight: 600;
  margin: 40px 0;
  border-left: 0px;
  font-family: 'Lato', sans-serif;
  text-align: center;
}


#sb-site{
  min-height: 1000px !important;
}


.centered {
  text-align: center;

  .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3 {
    float: none;
    display: inline-block;

    @media #{$underdesktop} {
      float: left;
    }
  }
  .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3 {
    float: none;
    display: inline-block;

    @media #{$underdesktop} {
      float: left;
    }
  }
}

.vcenter {
    display: flex;
    align-items: center;
    @media #{$undermd} {
      display: inherit;
      align-items: baseline;
    }
}

.container{
  @media #{$desktop-xl} {
    width: 1290px;
  }
}

/******** Integrazione di bootstrap *******/
.col-xs-offset-right-12 {
  margin-right: 100%;
}
.col-xs-offset-right-11 {
  margin-right: 91.66666667%;
}
.col-xs-offset-right-10 {
  margin-right: 83.33333333%;
}
.col-xs-offset-right-9 {
  margin-right: 75%;
}
.col-xs-offset-right-8 {
  margin-right: 66.66666667%;
}
.col-xs-offset-right-7 {
  margin-right: 58.33333333%;
}
.col-xs-offset-right-6 {
  margin-right: 50%;
}
.col-xs-offset-right-5 {
  margin-right: 41.66666667%;
}
.col-xs-offset-right-4 {
  margin-right: 33.33333333%;
}
.col-xs-offset-right-3 {
  margin-right: 25%;
}
.col-xs-offset-right-2 {
  margin-right: 16.66666667%;
}
.col-xs-offset-right-1 {
  margin-right: 8.33333333%;
}
.col-xs-offset-right-0 {
  margin-right: 0;
}

/******* Percorso ******/

#percorso {
  background: transparent;
  position: absolute;
  top: 60px;
  font-size: 16px;
  font-weight: 300;
  z-index: 10;
  opacity: 0.8;
  color: $black;
  padding: 30px 65px;
  /* margin-left: 20px; */

  @media #{$undermd} {
    padding: 30px 15px;
  }
}

.breadcrumb>li+li:before {
    padding: 0 0px !important;
    color: #ccc;
    content: " "  !important;
}

.breadcrumb {
    padding: 0px !important;
    margin-bottom: 0px !important;
    background-color: $white !important;
    border-radius: none;
    list-style: outside none none;
    margin-bottom: 0px;
    padding: 5px 10px;
    background: transparent;
    text-decoration: none;
    color: $white;
    position: relative;
    z-index: -1;
    @media #{$small-phone} {
      text-align: center;
    }

    li {
      font-size: 14px;
    }

    a, a:link{
      font-size: 14px;
      font-weight: 300;
      color: $darkGrey;
      text-decoration: none;
    }
    a, a:hover, a:focus{
      font-size: 14px;
      font-weight: 300;
      color: $black;
      text-decoration: underline;
    }

    li.active{
      font-size: 14px;
      font-weight: 300;
      color: $black;
      font-weight: 600;
    }

}

.breadcrumb > li + li::before {
    color: #999;
    content: none;
    padding: 0px;
}

.breadcrumb{
  .divider{
    color: $black;
  }
}

.contenuto{
  text-align: center;
  position: relative;
  z-index: 0;
}

/******* Main *******/

#main {
    padding: 0px 0;
    background-repeat: no-repeat;
    background-position: top center;
}

/****** Home ******/

/****** Categorie *******/

/****** Articoli *******/

figure.effect-one img {
  @include transition(all 0.35s);
	-webkit-transform: translate3d(0,0, 0);
	transform: translate3d(0,0,0);
  width: 240px;
  margin: 0 auto;
}

figure.effect-one figcaption {
}

figure.effect-one figcaption > div {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 15px;
	width: 100%;
	height: 25%;
}

figure.effect-one:hover figcaption > div {
  height: 70%;
  @include transition(height 0.2s);
}

figure.effect-one h3,
figure.effect-one p {

	-webkit-transform: translate3d(0,40px,0);
	transform: translate3d(0,40px,0);
}

figure.effect-one h3 {
  @include transition(-webkit-transform 0.35s);
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
}

figure.effect-one p {
	color: #4d4d4c;
	opacity: 0;
	-webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
	transition: opacity 0.2s, transform 0.35s;
}

figure.effect-one:hover {
  border: 3px solid rgba(255,224,46,1);
  -webkit-transition-duration: 0.35s;
	transition-duration: 0.35s;
}

figure.effect-one:hover p {
	opacity: 1;
}

figure.effect-one:hover img {
  width: 130px;
	-webkit-transform: translate3d(0,-40px,0);
	transform: translate3d(0,-40px,0);
}

figure.effect-one:hover p {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
	-webkit-transition-duration: 0.35s;
	transition-duration: 0.35s;
}


/********** Contatti ************/

/***** Pagina Cerca ******/
.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
    background-color: #e2343e;
    border-color: #e2343e;
    color: #fff;
    cursor: default;
    z-index: 3;
}

.search .nav li .active a {
    color: #ffffff;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.5em;
    padding: 5px 15px 3px;
    text-decoration: none;
    text-transform: none;
}

.search .nav li a {
    font-size: 15px;
    font-weight: 700;
    line-height: 1.5em;
    padding: 5px 15px 3px;
    text-decoration: none;
    text-transform: none;
}

/********* Iubenda **********/
.iubenda-cs-content{
    padding: 10px !important;
}
.iubenda-banner-content{

}

.iubenda-banner-content p {
  margin-bottom: 0 !important;
}

.iubenda-cs-rationale{
    max-width: 1170px !important;
}

a.iubenda-cs-close-btn {
  font-size: 16px !important;
}
