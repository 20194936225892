/******* Menu *******/
#brand {
  width: 200px;
  position: relative!important;
  padding: 5px 10px;
  margin: 0 auto;
  @media #{$undermd} {
    width: 200px;
    margin: 0;
  }
  @media #{$small-phone} {
    width: 180px;
    margin: 0;
  }
}

#navbar {
  // height:100px;
  background: $white;
  display: block;
  border:none;
  position: relative;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0;

  .fixed-theme{
    @include transition(0.4s);
  }

  .navbar-header{
    float: none !important;
  }

  .navbar{
    margin-bottom: 0;
  }

  .navbar-right{
    float: right!important;
    margin-right: 0px;
    padding: 9px 10px;
  }

  .navbar.navbar-fixed-top.fixed-theme {
      background-color: $white !important;
      border-color: #080808;
      box-shadow: 0 1px 2px rgba(66, 66, 66, 0.2);
      display: block;
      border:none;
  }

  .navbar-container.fixed-theme {
      padding: 0;
  }

  .nav>li>a {
    padding: 5px 20px;
    @media #{$desktop} {
      padding: 5px 10px;
    }
    @media #{$undermd} {
      padding: 5px 8px;
    }
  }
  .nav>li>a:hover {
    padding: 5px 20px;
    @media #{$desktop} {
      padding: 5px 10px;
    }
    @media #{$undermd} {
      padding: 5px 10px;
    }
  }

  .navbar-collapse{
    text-align: center;
    background: $black;

    li{
      a{
        color: $white;
        background: $black;
        font-size: 14px;

        @media #{$undermd} {
          font-size: 12px;
        }
        @media #{$tablet} {
          font-size: 11px;
        }
      }
      a:focus{
        color: $white;
        background: $black;
        font-size: 14px;
        @media #{$undermd} {
          font-size: 12px;
        }
      }
    }

    li.active{
      a{
        color: $green;
      }

      a:before{
        width: 0;
        height: 0;
        border-right: 7px solid #fff;
        border-top: 13px solid transparent;
        border-bottom: 0 solid transparent;
        content: "";
        position: absolute;
        margin-left: -12px;
        margin-top: 3px;
        background: $black;
      }
    }
    li:hover{
      a{
        color: $green;
        background: $black;
      }
    }
  }

  .navbar-nav{
    float: none;
    display: inline-block;
    margin: 0 auto;
    margin: 5px 0 0;
  }

  .contatti{
    i{
      font-size: 25px;
      display: inline-block;
      padding-bottom: 5px;
      padding: 0 12px;
      color: $black;
      margin-top: 15px;
    }

    .fa-envelope{
      display: inline-block;
      border-left: 2px solid $black;
      padding: 0 15px;
    }
    .fa-volume-control-phone{
      position: relative;
      top:-2px;
      font-size: 23px;
    }
  }
  .divider{
    background: $black;
    width: 2px;
    height: 25px;
    display: inline-block;
  }
}
.navbar-left{
  padding-left: 150px;
  @media #{$underxl} {
    padding-left: 0px;
  }

  .brand{
    @media #{$undermd} {
      margin:0 auto !important;
    }

  }
}
.navbar-right{
  padding-right: 150px !important;
  @media #{$underxl} {
    padding-right: 0px !important;
  }
  @media #{$undermd} {
    display: none;
  }

}
.navbar-toggle {
  border:1px solid $black;
  margin-top: 20px;

  @media #{$small-phone} {
    margin-top: 15px;
  }

  .icon-bar{
    background: $black;
  }
}

//menu responsive
#navigation-toggle {
    border: 1px solid $grey;
        padding: 10px 10px 8px !important;
}

#navigation-toggle span.nav-line {
    display: block;
    height: 3px;
    width: 20px;
    margin-bottom: 4px;
    background: $grey !important;
}



#gruemenu{
  width: 100%;
  text-align: center;
  border-top: 1px solid $black;
  border-radius: 0;
  position: relative;
  z-index: 0;
}

#gruemenu,
#gruemenu ul {
  text-align:center!important;
  box-shadow: none !important;
  li {
    float:none!important
  }
}

#gruemenu > ul > li > a {
  padding: 15px 20px 15px 25px;
  font-size: 14px !important;
}

//link
#gruemenu > ul > li > a{
    border-right: none !important;
    @media #{$underxl} {
      padding: 18px 12px 21px 12px;
      border-right: 1px solid rgba(80, 80, 80, 0.12);
      text-decoration: none;
      font-size: 11px !important;
      letter-spacing: 1px;
    }
    @media #{$underdesktop} {
      padding: 20px 5px 20px 5px;
      border-right: 1px solid rgba(80, 80, 80, 0.12);
      font-size: 10px !important;
      text-decoration: none;
      letter-spacing: 1px;
    }
}

// link attivi e link hover
#gruemenu > ul > li > a:active, #gruemenu > ul > li > a:hover {
    color: $green !important;
    background: $black !important;
}

//submenu
#gruemenu > ul > li.has-sub > a::after {
    border-top-color: #000000;
    right: 5px !important;
    @media #{$underdesktop} {
      top: 50%;
      margin-top: -3px !important;
    }
}

#gruemenu > ul > li.has-sub > a {
    padding-right: 20px;
}



#gruemenu ul > li > ul > li > a {
    font-size: 12px !important;
    color: $white !important;
    background: $black !important;
}
#gruemenu ul > li > ul > li > a:hover {
    font-size: 12px !important;
    color: $green !important;
    background: $black !important;
}


//menu responsive
#navigation-toggle {
    border: 1px solid $black;
}

#navigation-toggle span.nav-line {
    display: block;
    height: 3px;
    width: 20px;
    margin-bottom: 4px;
    background: $white !important;
}

.sidr {
  background: $black;
  color: $white;

  ul {
    border-top: none;
    border-bottom: none;
  }

  ul li {
    display: block;
    margin: 0;
    line-height: 48px;
    border-top: none;
    border-bottom: none;
    color: $white;

    a{
      color: $white;
      font-weight: 400;
      font-size: 18px;
    }
    a:hover, a:active{
      color: $green;
    }
  }

  ul li ul li a, .sidr ul li ul li span {
    color: $white;
    padding-left: 30px;
  }
}

.sidr ul li:hover a{
    color: $white !important;

}
.sidr ul li:hover a:hover{
    color: $green !important;

}
a.sidr-class-toggle-sidr-close:before {
    font-size: 20px;
    font-weight: bold;
    color: $black;
    margin: 0;
    text-decoration: none;
    content: "x";
    background: $white;
        padding: 2px 10px 6px 10px!important;
    border-radius: 50%;
}

.sidr ul li ul {
    border-bottom: none;
    margin: 0;
    padding-left: 20px;
}
